import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import hostedImageUrls from "../../utils/hosted_image";
import { Link, useNavigate } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import {  HiMiniUserGroup } from "react-icons/hi2";
import { GoChevronRight } from "react-icons/go";
import { PiStudentFill } from "react-icons/pi";
import { SiCodementor } from "react-icons/si";
import Cookies from "js-cookie";
import { VscSignOut } from "react-icons/vsc";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { IoHome } from "react-icons/io5";
import { AuthContext } from "../../contexts/AuthContext";

interface SetStateProps {     // Define the types of props that the component will receive
  onHeroChange: React.Dispatch<React.SetStateAction<string>>;
  landingState: string;
}

export default function LandingPage({ onHeroChange, landingState }: SetStateProps) {    // Define the component as a function component and pass the props to it
  const navigate = useNavigate();
  const menteeButtonRef = useRef<HTMLButtonElement>(null);
  const [leftNavOpen, setLeftNavOpen] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [topPopUp, setTopPopUp] = useState(false);
  const { authed, userProfile } = useContext(AuthContext); 

  useEffect(() => {
    if (menteeButtonRef.current) {
      menteeButtonRef.current?.focus();
    }
  }, []);

  useEffect(()=>{
    if(authed.status === 200){
      setIsUser(true);
    }
    else{
      setIsUser(false);
    }
  },[authed.status])

  const deleteCookie = async() =>{    // Function to delete the cookie and log out the user
    try{
      const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/logout/`,{
        headers: {
          'Authorization': `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`
        }
      });
      if(response?.status === 200){
        toast.success("Logged out successfully");
        Cookies.remove("GB_ACCESS_TOKEN");
        localStorage.removeItem("GB_USER_ID");
        localStorage.removeItem("userRole");
        Cookies.remove("role");
        window.location.reload();
      }
      else{
        toast.error("Failed to logout");
      }
    }
    catch(error){
      toast.error("Failed to logout");
      console.log(error);
    }
  }

  return (
    <div>
      <Toaster />
      <div className="top-0 flex flex-row w-full lg:px-[120px] px-[20px] justify-between items-center fixed z-50 h-[70px] bg-[#fff]">
        {/* header-content area */}
        <Link to={"/"}>
          <img
            src={hostedImageUrls?.growbinar_logo_full}
            alt="growbinar-logo"
            className="w-[195px] h-[107px] relative top-1"
          />
        </Link>
        <div
          className={`flex md:flex-row md:justify-between flex-col md:relative absolute flex-start md:w-[307px] max-w-[600px] md:min-h-[31px] top-0 md:left-auto ${
            leftNavOpen === false ? "-left-full" : "-left-0"
          }  bg-white text-black md:p-0 p-[20px] w-full min-h-[100vh]  md:shadow-none shadow-md  gap-[16px] duration-200 ease-in`}
        >
          <div
            className={`md:hidden flex justify-end   not-italic leading-6 font-medium`}
            onClick={() => {
              setLeftNavOpen(!leftNavOpen);
            }}
          >
            <AiOutlineClose className="text-[1.5rem]" />
          </div>
          {/* navigation to the ourTeam component */}
          <button
            onClick={() => {
              onHeroChange("ourTeam");
            }}
            className={`text-[1.3rem]  font-medium md:mt-0 mt-[40px] md:py-0 py-[10px]
                 border-b-2 md:translate-all ${
                   landingState === "ourTeam"
                     ? "md:border-gbpurple"
                     : "md:border-transparent "
                 }`}
          >
            <div className="md:block hidden whitespace-nowrap font-CircularStdMedium">
              Our Team
            </div>
            <div
              className="md:hidden flex flex-row justify-between  leading-6 items-center"
              onClick={() => setLeftNavOpen(false)}
            >
              <div className="flex flex-row gap-[16px] items-center">
                <HiMiniUserGroup className="" />
                <span>Our Team</span>
              </div>
              <GoChevronRight className="text-[1.7rem]" />
            </div>
          </button>
          {/* navigation to the mentee landing component */}

          <button
            className={`text-[1.3rem] text-center font-medium md:py-0  py-[10px]
                 border-b-2 md:translate-all ${
                   landingState === "menteeLanding"
                     ? "md:border-gbpurple"
                     : "md:border-transparent "
                 }`}
            onClick={() => {
              onHeroChange("menteeLanding");
            }}
          >
            <div className="md:block hidden font-CircularStdMedium">Mentee</div>
            <div
              className="md:hidden flex flex-row justify-between  leading-6 items-center"
              onClick={() => setLeftNavOpen(false)}
            >
              <div className="flex flex-row gap-[16px] items-center">
                <PiStudentFill className="" />
                <span>Mentee</span>
              </div>
              <GoChevronRight className="text-[1.7rem]" />
            </div>
          </button>

          {/* navigation to the mentor landing component */}

          <button
            className={`text-[1.3rem] text-center font-medium md:py-0  py-[10px]
                 border-b-2 md:translate-all ${
                   landingState === "mentorLanding"
                     ? "md:border-gbpurple"
                     : "md:border-transparent "
                 }`}
            onClick={() => {
              onHeroChange("mentorLanding");
            }}
          >
            <div className="md:block hidden font-CircularStdMedium">Mentor</div>
            <div
              className="md:hidden flex flex-row justify-between leading-6 items-center"
              onClick={() => setLeftNavOpen(false)}
            >
              <div className="flex flex-row gap-[16px] items-center">
                <SiCodementor className="" />
                <span>Mentor</span>
              </div>
              <GoChevronRight className="text-[1.7rem]" />
            </div>
          </button>

          {/* user profile sub toggles */}
          <div className="md:hidden flex flex-col gap-[16px] mt-[20px]">
            {/* authorized user */}
            {isUser ? (
              <>
                <Link to={`/${authed.role}/home`}>
                  {" "}
                  {/*  Navigate to the product page */}
                  <div className="border border-txtPurple rounded-[8px] p-[12px] flex items-center justify-center text-[20px] font-medium">
                    <p>Home</p>
                  </div>
                </Link>
                <button
                  onClick={() => {
                    deleteCookie();
                  }}
                >
                  {" "}
                  {/* log out the user */}
                  <div className="bg-red-400 text-white rounded-[8px] p-[12px] flex items-center justify-center  text-[20px] font-medium">
                    <p>Log Out</p>
                  </div>
                </button>
              </>
            ) : (
              // unauthorized user
              <>
                <Link to={"/login"}>
                  {" "}
                  {/* navigate to the user login */}
                  <div className="border border-txtPurple rounded-[8px] p-[12px] flex items-center justify-center text-[20px] font-medium">
                    <p>Login</p>
                  </div>
                </Link>
                <Link to={"/login"}>
                  {" "}
                  {/* navigate to the user signup */}
                  <div className="bg-txtPurple text-white rounded-[8px] p-[12px] flex items-center justify-center  text-[20px] font-medium">
                    <p>Signup</p>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
        {/* authorized user */}
        {!isUser ? (
          <div className="md:inline-flex  hidden gap-[10px]">
            {/* navigate to the user signup */}
            <button
              className="w-[100px] h-[48px] justify-center items-center rounded-[200px] border border-[#515151] whitespace-nowrap text-center text-[16px] font-medium leading-[24px] -tracking-[0.064px]"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign up
            </button>
            {/* navigate to the user login */}
            <button
              className="w-[100px] h-[48px] justify-center items-center rounded-[200px] bg-[#6c60fe] whitespace-nowrap text-center text-[16px] font-medium leading-[24px] -tracking-[0.064px] text-[#fff]"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          </div>
        ) : (
          // unauthorized user
          <div>
            <button
              onClick={() => {
                setTopPopUp(!topPopUp);
              }}
              className="flex flex-row items-center gap-[16px]"
            >
              <p className="text-xl font-medium md:block hidden">
                {userProfile?.name}
              </p>
              <img
                src={
                  userProfile?.profile_image_url
                    ? userProfile?.profile_image_url
                    : hostedImageUrls?.blankprofile
                }
                alt="profile"
                className="h-[50px] w-[50px] rounded-full md:block hidden"
              />
            </button>
            {/* sub profile toggle options */}
            {topPopUp && (
              <div className="absolute bg-slate-100 p-2 right-[30px] mt-3 lg:right-[120px] rounded-lg">
                <div className=" flex flex-col gap-y-2 cursor-pointer">
                  {/* Navigation to the user profile */}
                  <Link
                    to={`/${localStorage.getItem("userRole")}/home`}
                    className="font-medium hover:bg-blue-500 hover:text-white rounded-md flex items-center justify-between p-2 min-w-[130px]"
                  >
                    <span>Home</span>
                    <div className="text-xl flex items-center">
                      <IoHome />
                    </div>
                  </Link>
                  {/* logout */}
                  <button
                    className="font-medium hover:bg-blue-500 hover:text-white rounded-md flex items-center justify-between p-2"
                    onClick={() => {
                      deleteCookie();
                    }}
                  >
                    <span>Logout</span>
                    <div className="text-xl flex items-center">
                      <VscSignOut />
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {/* mobile screen */}
        {/* left nav bar toggle */}
        <div className="md:hidden block">
          <button
            onClick={() => {
              setLeftNavOpen(!leftNavOpen);
            }}
          >
            <BsList className="text-[28px]" />
          </button>
        </div>
      </div>
    </div>
  );
}
