import  { useState } from "react";
import Popup from "reactjs-popup";
import support from "../../assets/images/support.svg";
import signout from "../../assets/images/sign-out-icon.svg";
import settings from "../../assets/images/settings.svg";
import booking from "../../assets/images/booking.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";

interface ProfileCardProps {      // Define the types of props that the component will receive
  role: string | null;
}

export default function ProfileCard(props: ProfileCardProps) {
  const [userId] = useState(localStorage.getItem("GB_USER_ID"));    // Get the user id from the local storage

  const {userProfile} = useContext(AuthContext);    // Get the user profile from the AuthContext

  const navigate = useNavigate();
  const { setAuthed } = useContext(AuthContext); 


  const deleteCookie = async() =>{    // Function to delete the cookie and log out the user
    try{
      const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/logout/`,{
        headers: {
          'Authorization': `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`   // Fixed the typo in the header name
        }
      });
      if(response?.status === 200){
        toast.success("Logged out successfully");
        Cookies.remove("GB_ACCESS_TOKEN");    // Remove the cookie
        localStorage.removeItem("GB_USER_ID");    // Remove the user id from the local storage
        localStorage.removeItem("userRole");    // Remove the user role from the local storage
        Cookies.remove("role");   // Remove the role from the cookie
        setAuthed(400);    // Set the auth status to false
        navigate("/");    // Redirect to the login page
      }
      else{
        toast.error("Failed to logout");
      }
    }
    catch(error){
      toast.error("Failed to logout");
    }
  }

  return (
    <div>
      {/* pop up for the profile card */}
      <Popup
        trigger={
          <img className="cursor-pointer rounded-full" src={(userProfile?.profile_image_url) ? userProfile?.profile_image_url:hostedImageUrls?.blankprofile} alt="profile" />
        }
        position="right center"
      >
        <div className="w-[368px] h-[391px] rounded-md shadow-md bg-[#fff]">
          {/* redirect to the user profile */}
          <Link className="flex items-center justify-start gap-[25px] p-3" to={`/${props.role}/${userId}`}>
            <img src={(userProfile?.profile_image_url) ? userProfile?.profile_image_url:hostedImageUrls?.blankprofile} className="h-[60px] w-[60px] rounded-full" alt="profile"/>
            <div>
              <p className="text-[#263238] text-[20px] not-italic font-medium leading-5">
                {userProfile?.name}
              </p>
              <p className="text-[#3F3D4D] text-[14px] not-italic font-normal whitespace-nowrap">
                View Profile
              </p>
            </div>
          </Link>
          <div className="mx-auto border border-[#D9D9D9] w-[300px] divide-y-2 h-[1px] "></div>
          <br />
          {/* user bookings */}
          <div className="mx-[20px] flex flex-col gap-2">
            <Link className="flex items-center justify-left gap-[15px]" to={`/${props?.role}/booking`}>
              <div className="inline-flex w-[44px] h-[44px] rounded-lg bg-[#ECEBFA] items-center justify-center cursor-pointer">
                <img src={booking} className="w-[24px] h-[24px]" alt="profile"/>
              </div>
              <div>
                <p className="text-[#263238] text-[18px] not-italic font-medium">
                  My Bookings
                </p>
                <p className="text-[#636B78] text-[16px] not-italic font-normal">
                  View and manage all your bookings
                </p>
              </div>
            </Link>
            {/* supports and feedbacks still haven't implemented */}
            <div className="flex gap-[15px] cursor-not-allowed">
              <div className="inline-flex w-[44px] h-[44px] rounded-lg bg-[#ECEBFA] items-center justify-center cursor-pointer">
                <img src={support} className="w-[24px] h-[24px]" alt="support"/>
              </div>
              <p className="text-[#636B78] text-[18px]  not-italic font-medium leading-9 ">
                Support
              </p>
            </div>
            {/* settings for user */}
            <div className="flex gap-[15px] cursor-not-allowed">
              <div className="inline-flex w-[44px] h-[44px] rounded-lg bg-[#ECEBFA] items-center justify-center cursor-pointer">
                <img src={settings} className="w-[24px] h-[24px]" alt="settings" />
              </div>
              <p className="text-[#636B78] text-[18px]  not-italic font-medium leading-9 ">
                Settings
              </p>
            </div>
            {/* log out  */}
            <button className="flex gap-[15px]" onClick={()=>deleteCookie()}>
              <div className="inline-flex w-[44px] h-[44px] rounded-lg bg-[#ECEBFA] items-center justify-center cursor-pointer">
                <img src={signout} className="w-[24px] h-[24px]" alt="signout" />
              </div>
              <p className="text-[#636B78] text-[18px]  not-italic font-medium leading-9 ">
                Signout
              </p>
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
