import { useEffect } from "react";
import { useState } from "react";
import axios from "axios"
import { setCookie } from "../../utils/cookies/cookieCurd";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";

// login page

export default function LoginContent({ setSignup }: { setSignup : Function}) {

  const [showEmailSignin, setShowEmailSignin] = useState(false);
  const [showSocialSignin, setShowSocialSignin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<string>("role");
  const navigate = useNavigate();
  const [loading,setLoading] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const {authed, setAuthed} = useContext(AuthContext);
  
  useEffect(()=>{
    if(authed?.status === 200){
      navigate(`/${authed?.role}/home`)
    }
  },[authed?.role, authed?.status, navigate])

  // handle the email sign in

  const handleEmailSignin = async () => {
    setLoading(0)
    try{
      if(role !== "mentor" && role !== "mentee"){
        toast.error('Please select a role')
        setLoading(1)
        return;
      }
      const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/login/`, {
        email_id: email,
        password: password,
        user_role : role
      });
      
      setLoading(1)

      setCookie('GB_ACCESS_TOKEN', response?.data?.token, {
        expires: 7,
        path: '/',
        sameSite: 'None',
        secure: true,
      })
      
      localStorage.setItem('GB_USER_ID', response?.data?.id)   
      localStorage.setItem('userRole', role as string)
      
      if (response?.data?.message === "User details not completely entered."){ 
          setAuthed({status : 400 , authed:true ,verified: true, role:role})
          
          if(role === 'mentee'){
            navigate('/menteestepper')
          }
          else if(role === 'mentor'){
            navigate('/mentorstepper')
          }
        }
      
      else{
        setAuthed({status : 200 , authed:true ,verified: true, role:role})
        navigate(`/${role}/home`)
      }
    }catch(error : any){
      if (error.response?.data?.message === "Verify your email") {
        setAuthed({status : 400 , authed:true ,verified: false, role:role})
        navigate('/signup/emailVerification')
      }
      if(error.response?.data?.message === "Invalid Credentials") {
        setLoading(1);
        toast.error('Incorrect password')
        return;
      }

      if(error.response?.data?.message === "User not found"){
        setLoading(1);
        toast.error('User does not exists')
        return;
      }
      setLoading(1);
    }
  };

  // handle the social sign in

  const handleSocialSignin = () => {
    setEmail("");
    setPassword("");
    setShowSocialSignin(true);
    setShowEmailSignin(false);
  };

  const handleForgotPassword = async() => {
    try{
      const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/forget_password/`, {
        email_id: email,
        user_type: role,
      });
      toast.success(response?.data?.message)
    }
    catch(error : any){

    }
  }


  return (
    <div>
      <Toaster/>
      <div className="flex flex-row gap-2 items-center justify-end px-4 py-2">
        {/* don't have an account ? */}
        <p className="text-base -tracking-[0.064px] font-normal font-CircularStdMedium ">
          Don't have an account?
        </p>
        <button
          className="text-base rounded border-[2px] border-black font-normal font-CircularStdMedium p-1"
          onClick={() => {
            setSignup("signup");
            navigate('/signup')
          }}
        >
          SignUp
        </button>
      </div>
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-50px)]">
        <div>
          <img
            src={hostedImageUrls.growbinar_logo_short}
            alt="growbinar_logo"
            className="w-[150px] h-[150px] flex-shrink-0"
          />
        </div>
        <div className="">
          <p className="text-center sm:text-[36px] text-[20px] font-light font-CircularStdBlack text-[#9F9F9F] whitespace-nowrap">
            Empower your career advancement.
          </p>
        </div>
          {/* social signin */}
        {showSocialSignin && (
          <div className="flex flex-col gap-4 p-4 md:p-8 w-full items-center justify-center">
            <button className="flex w-full sm:w-[479px] h-[54px] border-[2px] border-black rounded-lg gap-4 items-center justify-center opacity-50 cursor-not-allowed">
              <img
                src={hostedImageUrls.google_logo}
                alt="google_logo"
                className="w-[24px] h-[24px]"
              />
              <p className="text-base font-CircularStdMedium font-normal ">
                Login with Google
              </p>
            </button>
            <button className="flex w-full sm:w-[479px] h-[54px] border-[2px] border-black rounded-lg gap-4  items-center justify-center opacity-50 cursor-not-allowed">
              <img
                src={hostedImageUrls.linkedin_logo}
                alt="google_logo"
                className="w-[24px] h-[24px]"
              />
              <p className="text-base font-CircularStdMedium font-normal ">
                Login with LinkedIn
              </p>
            </button>
            <p className="text-center font-CircularStdBlack text-[#9F9F9F] p-2">
              OR
            </p>
            <button
              className="h-[54px] w-full sm:w-[479px] border-[2px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center"
              onClick={()=>{
                setShowEmailSignin(true);
                setShowSocialSignin(false);
              }}
            >
              <p className="text-base font-CircularStdMedium font-normal hover:opacity-80 duration-200 ease-in">
                Login with Email
              </p>
            </button>
          </div>
        )}
        {/*  email signin section */}
        {showEmailSignin && (
          <div className="flex flex-col gap-4 p-8 w-full items-center justify-center">
            {/* Signup Form Content Here */}
            <input
              type="email"
              value={email}
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value.trim())}
              className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2"
            />
            {
              showPassword ? <input
                type="text"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2`}
              /> : <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2`}
              />
            }

              <div className="w-full sm:w-[479px] flex gap-4 items-center justify-end">
                <input type="checkbox" className="h-5 w-5" onChange={()=>{setShowPassword(!showPassword)}}/>
                <label className="text-base font-CircularStdMedium font-normal">Show Password</label>
              </div>

            <select className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2" onChange={(e : any)=>setRole(e.target.value)} disabled = {loading===0?true:false}>
              <option value={'role'} disabled selected hidden>Role</option>
              <option className="" value={'mentee'}>Mentee</option>
              <option value={'mentor'}>Mentor</option>
            </select>
            {
              loading === 1 ? <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center"
                onClick={handleEmailSignin}>
                <p className="text-base font-CircularStdMedium font-normal">
                  Continue
                </p>
              </button>
              :
                <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 flex items-center justify-center">

                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                      <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                    </path>
                  </svg>

                </button>
            }
          
            <button
              className="w-full sm:w-[479px] h-[54px] text-black bg-white rounded-lg gap-4 items-center justify-center border-[2px] border-black"
              onClick={handleSocialSignin}
            >
              <p className="text-base font-CircularStdMedium font-normal ">
                Go Back
              </p>
            </button>
            {/* <button className="w-full sm:w-[479px] h-[54px] flex justify-end text-gbpurple">Forget password</button> */}
            <Link className="w-full sm:w-[479px] h-[54px] flex justify-end text-gbpurple" to={'/forgotpassword'}>Forget password</Link>
          </div>
        )}
      </div>
    </div>
  );
}
