import search from "../../assets/images/Search.svg";

export default function MenteeHero() {

  // mentee hero component
  return (
    <div>
      <div className="flex items-center justify-center mt-[120px]">
        <div className="ils-img-container">
          <div className="absolute inset-0 flex flex-col justify-center h-full items-center">
            <div className="lg:w-[900px] md:w-[700px] sm:w-[600px] w-[370px] text-center  md:text-5xl text-3xl">
              <div className="text-[#000] font-CircularStdBold text-center leading-[50px] sm:leading-[82px] tracking-1 whitespace-pre-line p-2">
                Unlock your path to career success with expert mentors
              </div>
            </div>
            <br />
            <div className="text-center p-2">
              <div className="sm:w-auto w-[370px] text-[#000] lg:text-lg md:text-md font-light leading-8 tracking-2">
                Start your career journey confidently with a{" "}
                <span>
                  <button className="h-[38px] w-[154px] text-sm rounded-full text-gbpurple bg-[#F2F1FF] font-medium text-center font-CircularStdMedium">
                    FREE TRAIL SESSION
                  </button>
                </span>{" "}
                Choose your mentor and
              </div>
              <div className="text-[#000] text-lg font-light leading-8 tracking-2">
                explore the path to success.
              </div>
            </div>
            <br />
            <div className="flex items-center justify-center">
              <div className="md:w-[624px] h-[84px] inline-flex pr-[8px] pl-[31px] items-center justify-between rounded-[258px] bg-[#fff] shadow-newsletter">
                <div className="flex gap-[10px] w-[100%] h-full items-center justify-start">
                  <img
                    src={search}
                    alt="search-icon"
                    className="md:h-[36px] h-[24px] md:w-[37px] w-[20px]"
                  />
                  <input
                    placeholder="Search Mentors by skills"
                    className="w-[100%] h-full text-[#BABABA] lg:text-lg md:text-md md:hidden font-normal leading-6 tracking-3 outline-0"
                  />
                  <input
                    placeholder="Search Mentors by company, skills or role"
                    className="w-[100%] h-full text-[#BABABA] lg:text-lg md:text-md hidden md:block font-normal leading-6 tracking-3 outline-0"
                  />
                </div>
                <button className="w-auto md:w-[145px] h-auto p-[16px] md:h-[68px] flex-shrink-0 rounded-[100px] bg-gbpurple text-lg text-white font-normal leading-8">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
