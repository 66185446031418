import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import ValidationError from '../../components/ValidationError';
import { Country, State } from 'country-state-city';

import { AutoCompleteInput } from '../../components/AutoCompleteInput';
import { SelectProfileImagePopup } from '../../components/SelectProfileImagePopup';
import { formStateType, MentorFormError, MentorImages } from '../../utils/MentorUtils';
import { handleError } from '../../utils/HelperFunctions';
import { Gender, interestedAreasOption,languagesOptions } from './data';
import Cookies from 'js-cookie';
import axios from 'axios';
import Alert from '@mui/material/Alert';

interface MentorProfileEditProps {
    closeEditPopUp: any;
    displayToastMessage: Function;
}

const MentorProfileEdit  = (props : MentorProfileEditProps) => {
    const { closeEditPopUp } = props;

    const { userProfile } = useContext(AuthContext);
    const [profileImageCon, setProfileImageCon] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>(MentorImages[0]);
    const [states, setStates] = useState([]);
    const [countryCode, setCountryCode] = useState('' as any)
    const [, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSelectedImage = async (image: any) => {
        setSelectedImage(MentorImages.find((img) => img.key === image));
    }

    // mentor profile default details and errors
    const [formError, setFormError] = useState<MentorFormError>({
        first_name: [],
        phone_number: [],
        date_of_birth: [],
        country: [],
        city: [],
        role: [],
        organization: [],
        description: [],
        areas_of_expertise: [],
        languages: [],
        profile_picture_url: [],
    });

    const [mentor_profile, setMentorProfile] = useState<formStateType>({
        first_name: "",
        last_name: "",
        country: "",
        is_email_verified: false,
        phone_number: "",
        languages: [],
        gender: "",
        date_of_birth: "",
        city: "",
        profile_picture_url: selectedImage.url,
        areas_of_expertise: [],
        description: "",
        role: "",
        organization: "",
        is_experience: true,
        number_of_likes: 0,
        MentorExperience: 0,
    });

    const updateMentorProfileState = (key: keyof typeof mentor_profile, value: string) => { 
        setMentorProfile((prevStates) => {
            return {
                ...prevStates,
                [key]: value,
            };
        });
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        key: keyof typeof mentor_profile,
        value: string,
        resetInputFiledAndRecommendation: Function,
    ) => {
        if (event.key === "Enter" && value !== "") {
            if (key as string === "area_of_interest") {
                key = "areas_of_expertise"
            }
            if ((key === "areas_of_expertise" || key === "languages") && mentor_profile[key].includes(value)) {
                event.currentTarget.value = "";
                resetInputFiledAndRecommendation();
                return;
            }
            const newInputValues = {
                ...mentor_profile,
                [key]: [...(mentor_profile[key] as string[]), value.trim()],
            };
            setMentorProfile(newInputValues);
            event.currentTarget.value = "";
            resetInputFiledAndRecommendation();
        }
    };

    const handleOptionClick = (key: keyof typeof mentor_profile, value: string) => {
        if (key as string === "area_of_interest") {
            key = "areas_of_expertise"
        }
        if ((key === "areas_of_expertise" || key === "languages") && mentor_profile[key].includes(value)) {
            return;
        }
        setMentorProfile((prevStates) => {
            return {
                ...prevStates,
                [key]: [...(mentor_profile[key] as string[]), value],
            };
        });
    }

    const handleMultiInputDelete = (
        key: keyof typeof mentor_profile,
        index: number
    ) => {
        setMentorProfile((prevStates: any) => {
            return {
                ...prevStates,
                [key]: [
                    ...prevStates[key].slice(0, index),
                    ...prevStates[key].slice(index + 1),
                ],
            };
        });
    };

    const Experience = ["0 - 1","1 - 3", "3 - 5", "5+", "10+"];

    const handleCountryChange = async (event: any) => {

        const countryIsoCode = event.target.value;
        if (countryIsoCode === "") {
            updateMentorProfileState("country", "")
            updateMentorProfileState("city", "")
            setStates([])
            return;
        }

        setCountryCode(countryIsoCode)
        const country: any = Country.getCountryByCode(countryIsoCode);
        updateMentorProfileState("country", country["name"])
    };

    useEffect(()=>{
        setCountryCode(Country.getAllCountries().find((country) => {
            return(country.name === mentor_profile.country)
        })?.isoCode)
    },[mentor_profile.country])

    useEffect(()=>{
        setStates(State.getStatesOfCountry(countryCode) as any);
    },[countryCode])

    const handleStateChange = (event: any) => {
        const stateIsoCode = event.target.value;
        if (stateIsoCode === "" || countryCode === "") {
            updateMentorProfileState('city', "")
            return
        }
        const state: any = State.getStateByCodeAndCountry(stateIsoCode, countryCode);
        updateMentorProfileState("city", state["name"])
    };

    const confirmImage = () => {
        updateMentorProfileState('profile_picture_url', (MentorImages.find((img) => img.key === selectedImage.key) as { url: string })?.url);
    }

    const handlelImagePopup = () => {
        setProfileImageCon(!profileImageCon)
    }


    useEffect(() => {
        if (userProfile) {
            setSelectedImage(MentorImages.find((img) => img.path === userProfile.profile_image_url));
            setMentorProfile((prevStates) => {
                return ({
                    ...prevStates,
                    MentorExperience: userProfile?.years_of_experience,
                    areas_of_expertise: userProfile?.background?.expertise,
                    country: userProfile.country,
                    date_of_birth: userProfile.date_of_birth,
                    first_name: userProfile?.name.split(' ')[0],
                    last_name: userProfile?.name.split(' ')[1],
                    gender : userProfile?.gender,
                    city: userProfile?.location,
                    // is_email_verified : userProfile.is_email_verified,
                    // is_experience : userProfile.is_experience,
                    // number_of_likes : userProfile.number_of_likes,
                    phone_number: userProfile.phone_number,
                    organization: userProfile?.organisation,
                    // currentStudy: userProfile.current_study,
                    description: userProfile?.overview,
                    role: userProfile?.designation,
                    profile_picture_url: userProfile?.profile_image_url,
                    languages: userProfile?.background?.fluency,
                
                })
            })
        }
    }, [userProfile])

    const handleSubmit = async () => {
        const idDatasValidated = handleError(mentor_profile, formError, setFormError);

        if (idDatasValidated) {
            setIsError(false)
            const { is_email_verified, ...rest } = mentor_profile
            setLoading(true)
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const response = await axios.patch(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/mentor_details/`,{
                  ...rest
                },{
                  headers:{
                    "Authorization": `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
                  }
                })
                setLoading(false)
                if(response?.status === 200){
                    window.location.reload()
                    // toast message, is error
                }
            } catch (error: any) {
                setLoading(false)
            }
        }
        else{
            setIsError(true)
        }
        setLoading(false)
    };


    return(
        <>
            {/* Profile Pick Pop up */}
            {profileImageCon && <SelectProfileImagePopup ProfileImages = {MentorImages} setProfileImageCon={setProfileImageCon} handleSelectedImage={handleSelectedImage} selectedImage={selectedImage} confirmImage={confirmImage} />}
            <div className='text-[#263238] text-[32px] font-bold p-2 flex gap-[32px]'>
                Edit your profile
                
            </div>
            <div className='text-black text-left text-[18px] px-2 leading-9 font-medium not-italic'>
                Update your details and preferences to make your profile more impactful
            </div>

            {/* Profile Picture */}

            <div className='flex flex-col mt-4'>
                <div className={`flex flex-row gap-[12px] items-center`}>
                    <button className={`w-[6.6rem] h-[6.6rem] rounded-full bg-[#A1A6AD] hover:bg-black overflow-hidden ${formError.profile_picture_url.length > 0 ? 'border-2 border-red-500 shake' : ''}`} onClick={handlelImagePopup}>
                        <img src={mentor_profile.profile_picture_url} alt='profile'></img>
                    </button>
                    <div className='flex flex-col gap-[8px]'>
                        <p className="text-txtPurple font-semibold">Set a profile picture</p>
                        <p className="text-txtGrey">select a file</p>
                    </div>
                </div>
                <ValidationError errors={formError["profile_picture_url"]} />
            </div>

            <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
                {/* full name */}
                Your full name
            </div>
            <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row'>

                {/* first name */}
                <div>
                    <input
                        type="text"
                        required value={mentor_profile.first_name}
                        onChange={(e) => updateMentorProfileState('first_name', e.target.value)}
                        placeholder="First name"
                        className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.first_name.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                    />
                    <ValidationError errors={formError["first_name"]} />
                </div>

                {/* last name */}
                <input
                    type="text"
                    required value={mentor_profile.last_name}
                    onChange={(e) => updateMentorProfileState('last_name', e.target.value)}
                    placeholder="Last name (optional)"
                    className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple`}
                />

            </div>

            {/* Text area description section */}

            <div className='ml-0 md:ml-2 mt-4'>
                <textarea
                    required value={mentor_profile.description}
                    onChange={(e) =>updateMentorProfileState('description', e.target.value)}
                    placeholder="Description"
                    className={`border-[2px] min-h-[124px] border-[#c0c0c0] rounded-lg  w-full sm:w-[616px] h-[74px] text-left p-2 focus:outline-txtPurple ${formError.description.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                />
                <ValidationError errors={formError["description"]} />
            </div>

            {/* Personal Details */}

            <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
                Personal details
            </div>

            <div className='flex gap-4 w-full sm:w-[306px]'>

                {/* gender  */}
                <select required id="gender" className={`outline-2 ml-0 sm:ml-2 w-[40%] border-[2px] border-[#c0c0c0] rounded-lg h-[54px] text-left p-2 focus:outline-txtPurple `} value={mentor_profile.gender}
                    onChange={(e) => {
                        updateMentorProfileState('gender', e.target.value)
                    }}>
                    {
                        Gender.map((item, index) => {
                            return (
                                <option className='' value={item} key={index}>{item}</option>
                            )
                        })
                    }
                </select>

                {/* date of birth */}
                <div className='md:w-[60%] w-full'>
                    <input
                        className={`border-[2px] border-[#c0c0c0] w-full rounded-lg h-[54px] text-left p-2 focus:outline-txtPurple ${formError.date_of_birth.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`}
                        type="date" id="dob" value={mentor_profile.date_of_birth}
                        placeholder="" required onChange={(e) => updateMentorProfileState('date_of_birth', e.target.value)}
                    />
                    <ValidationError errors={formError["date_of_birth"]} />
                </div>
            </div>
            {/* Phone number edit */}
            <div className='flex px-0 md:px-2 flex-col mt-4'>
                <input
                    type="text"
                    value={mentor_profile.phone_number}
                    onChange={(e) => updateMentorProfileState('phone_number', e.target.value)}
                    placeholder="phone number"
                    className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.phone_number.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
                />
                <ValidationError errors={formError["phone_number"]} />
            </div>
            {/* Country and state */}
            <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row mt-4'>
                <div>
                    <select id="country" className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.country.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`} required onChange={(e) => { handleCountryChange(e) }}>
                        <option value={mentor_profile.country} >{mentor_profile.country}</option>
                        {
                            Country.getAllCountries().map((country, index) => {
                                return (
                                    <option key={country.isoCode} className='' value={country.isoCode}>{country.name}</option>
                                )
                            })
                        }
                    </select>
                    <ValidationError errors={formError["country"]} />
                </div>
                <div>
                    <select id="state" className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.city.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`} onChange={handleStateChange} required>
                        <option value={mentor_profile.city}>{mentor_profile.city}</option>
                        {
                            (states as any[]).map((state, index) => {
                                return (
                                    <option key={state.isoCode} className='' value={state.isoCode}>{state.name}</option>
                                )
                            })
                        }
                    </select>
                    <ValidationError errors={formError["city"]} />
                </div>
            </div>
            {/* Professional Details */}
            <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
                Professional details
            </div>
            <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row'>

                {/* role*/}
                <select className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple `} onChange={(e) => updateMentorProfileState('MentorExperience', e.target.value)} value={mentor_profile?.MentorExperience as string | number}>
                        {
                            Experience?.map((item, index) => {
                                return (
                                    <option key={index} value={item}>{item + " Years"}</option>
                                )
                            })
                        }
                </select>

                {/* organization */}
                <div>
                    <input
                        type="text"
                        required value={mentor_profile.organization}
                        onChange={(e) => updateMentorProfileState('organization', e.target.value)}
                        placeholder="Organization"
                        className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.organization.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                    />
                    <ValidationError errors={formError["organization"]} />
                </div>
            </div>
            {/* Desingation */}
            <div className='flex px-0 md:px-2 flex-col mt-4'>
                <input
                    type="text"
                    value={mentor_profile.role}
                    onChange={(e) => updateMentorProfileState('role', e.target.value)}
                    placeholder="Designation"
                    className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.role.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
                />
                <ValidationError errors={formError["role"]} />
            </div>
            {/* Areas of Interest */}
                <div className='ml-0 md:ml-2 mt-4 md:max-w-[615px] flex flex-col gap-1'>
                    <AutoCompleteInput
                        id={"area_of_interest"}
                        label=""
                        formError={formError}
                        options={interestedAreasOption}
                        formState={mentor_profile}
                        handleKeyDown={handleKeyDown}
                        handleOptionClick={handleOptionClick}
                        handleMultiInputDelete={handleMultiInputDelete}
                        placeholder="your interest"
                        labelClassName="text-xl font-CircularStdMedium"
                        inputClassName={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.areas_of_expertise.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                        optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
                    />
                </div>
                
                {/* Languages Known */}
                <div className='ml-0 md:ml-2 mt-4 md:max-w-[615px] flex flex-col gap-1'>
                    <AutoCompleteInput
                        id={"languages"} //formstate key
                        label=""
                        formError={formError}
                        options={languagesOptions}
                        formState={mentor_profile}
                        handleKeyDown={handleKeyDown}
                        handleOptionClick={handleOptionClick}
                        handleMultiInputDelete={handleMultiInputDelete}
                        placeholder="Languages Known"
                        labelClassName="text-xl font-CircularStdMedium"
                        inputClassName={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.languages.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                        optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
                    />
                </div>
                <div className='pl-0 md:px-2 mt-6 flex gap-4'>
                    <button onClick={() => { handleSubmit() }} className='px-4 py-2 bg-gbpurple text-white rounded-lg hover:opacity-75'>Submit</button>
                <button className='px-4 py-2 text-black border border-[#c0c0c0] rounded-lg hover:opacity-75' onClick={()=>closeEditPopUp(false)}>Close</button>
                </div>
                <div className={`${isError ? "block":"hidden"} mt-4`}>
                    <Alert severity="error">
                        Please fill all the required fields
                    </Alert>
                </div>
    
        </>
    )
}
export default MentorProfileEdit;