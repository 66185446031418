import { useEffect, useState } from "react";
import ValidationError from "../../components/ValidationError";

import { MdClose } from "react-icons/md";
import { interestedAreasOption, languagesOptions, MenteeFormState, MenteeFormError } from "../../utils/MenteeStepperHelper";
import { AutoCompleteInput } from "../../components/AutoCompleteInput";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
interface MenteeProfileProps {
    formState: MenteeFormState;
    updateFormState: Function;
    handleKeyDown: Function;
    handleMultiInputDelete: Function;
    handleOptionClick: Function;
    formError: MenteeFormError;
}

export default function MenteeStepperBio({ formState, updateFormState, handleKeyDown,handleOptionClick, handleMultiInputDelete, formError }: MenteeProfileProps){

    const [area_of_expertiseInput] = useState<string>("")      

    const [intrestedAreaOption] = useState<string[]>(interestedAreasOption);

    const [languagesOption] = useState<string[]>(languagesOptions);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [filteredAreasOfExpertise, setFilteredAreasOfExpertise] = useState<string[]>(intrestedAreaOption);


      useEffect(()=>{
        if(area_of_expertiseInput.length > 0){
            setFilteredAreasOfExpertise(intrestedAreaOption.filter((item) => item.toLowerCase().includes(area_of_expertiseInput.toLowerCase())))
        }
        else{
            setFilteredAreasOfExpertise([])
        }
      },[area_of_expertiseInput,intrestedAreaOption])

    return (
      <>
        {/* Role input filed */}
        <div className="flex flex-col gap-[8px]">
          <label htmlFor="role" className="text-xl font-CircularStdMedium">
            Role
          </label>
          <input
            id="role"
            className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] 
                        shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
                          formError.role.length > 0
                            ? "!border-2 border-red-500 shake"
                            : ""
                        }`}
            placeholder="Software Engineer, ML Engineer ..."
            value={formState.role}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFormState("role", e.target.value)
            }
          />
          <div className="md:w-[86%] w-full">
            <ValidationError errors={formError["role"]} />
          </div>
        </div>

        {/* Area of Interest input field */}

        <div className="flex flex-col gap-[8px]">
          <AutoCompleteInput
            id={"area_of_interest"}
            label="Area of Interest"
            formError={formError}
            options={intrestedAreaOption}
            formState={formState}
            handleKeyDown={handleKeyDown}
            handleOptionClick={handleOptionClick}
            handleMultiInputDelete={handleMultiInputDelete}
            placeholder="Web Development, Data Science ..."
            labelClassName="text-xl font-CircularStdMedium"
            inputClassName={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
              formError.areas_of_expertise.length > 0
                ? "!border-2 border-red-500 shake"
                : ""
            }`}
            optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
          />
        </div>

        {/* current study input field */}

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="currentstudy"
            className="text-xl font-CircularStdMedium"
          >
            Where do you currently study ?
          </label>
          <input
            id="currentstudy"
            value={formState.currentStudy}
            className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] 
                        shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
                          formError.currentStudy.length > 0
                            ? "!border-2 border-red-500 shake"
                            : ""
                        }`}
            onChange={(e) => updateFormState("currentStudy", e.target.value)}
            placeholder="Name of the college or university ..."
          ></input>
          <div className="md:w-[86%] w-full">
            <ValidationError errors={formError["currentStudy"]} />
          </div>
        </div>

        {/* Language input field */}

        <div className="flex flex-col gap-[8px]">
          <AutoCompleteInput
            id={"languages"}
            label="Languages Known"
            formError={formError}
            options={languagesOption}
            formState={formState}
            handleKeyDown={handleKeyDown}
            handleOptionClick={handleOptionClick}
            handleMultiInputDelete={handleMultiInputDelete}
            placeholder="English, Tamil, Hindi ..."
            labelClassName="text-xl font-CircularStdMedium"
            inputClassName={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
              formError.languages.length > 0
                ? "!border-2 border-red-500 shake"
                : ""
            }`}
            optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
          />
        </div>
        {/* Organization input field */}

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="description"
            className="text-xl font-CircularStdMedium"
          >
            Course of Study
          </label>
          <input
            id="description"
            value={formState.organization}
            className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] 
                        shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
                          formError.organization.length > 0
                            ? "!border-2 border-red-500 shake"
                            : ""
                        }`}
            placeholder="Computer Science and Engineering, Data Science ..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFormState("organization", e.target.value)
            }
          />
          <div className="md:w-[86%] w-full">
            <ValidationError errors={formError["organization"]} />
          </div>
        </div>

        {/* Description input field */}

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="description"
            className="text-xl font-CircularStdMedium"
          >
            Great! What does describe you the best ?
          </label>
          <textarea
            id="description"
            value={formState.description}
            className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] min-h-[124px]
                        shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${
                          formError.description.length > 0
                            ? "!border-2 border-red-500 shake"
                            : ""
                        }`}
            placeholder="Tell us more about yourself, your goals and what you love.."
            onChange={(e) => updateFormState("description", e.target.value)}
          />
          <div className="md:w-[86%] w-full">
            <ValidationError errors={formError["description"]} />
          </div>
        </div>
      </>
    );
}