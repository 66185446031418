import "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Layout from "./layouts/DashboardLayout";
import { useState, useEffect } from "react";
import LandingPageLayout from "./layouts/LandingPageLayout";
import SignUpLoginLayout from "./layouts/SignUpLoginLayout";
import StepperLayout from "./layouts/StepperLayout";
import MenteeDashboard from "./pages/MenteeDashboard/Dashboard";
import Bookings from "./containers/Bookings";
import BrowseMentors from "./containers/BrowseMentors/BrowseMentors";
import BackHeader from "./components/BackHeader";
import ToggleHeader from "./components/ToggleHeader";
import MentorProfile from "./containers/MentorProfile";
import MentorDashboard from "./pages/MentorDashboard/MentorDashboard";
import MenteeProfile from "./pages/MenteeProfile";
import UnAuth from "./components/UnAuth";
import MenteeAuth from "./utils/MenteeAuth";
import MentorAuth from "./utils/MentorAuth";
import Auth from "./hooks/useAuth";
import MenteeBooking from "./pages/MenteeBooking";
import { AuthContext } from "./contexts/AuthContext";
import Loader from "./components/Loader";
import MentorStepperAuth from "./utils/MentorStepperAuth";
import MenteeStepperAuth from "./utils/MenteeStepperAuth";
import ResetPassword from "./pages/ResetPassword";


function App() {
  const queryClient = new QueryClient();
  const [, setRole] = useState<string | null>(null);    // stores the user role
  const userId = localStorage.getItem("GB_USER_ID");
  const [userProfile, setUserProfile]  = useState<any>(null);
  const [authed,setAuthed] = useState<any>(null);

  useEffect(() => {
    userAuth()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // useEffect(()=>{
  //   if(authed?.authed === false){
  //     navigate('/')
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[authed?.authed])

  const userAuth = async () => {
    try {
      const user = await Auth();
      // setAuthed(user)
      setAuthed(user)
      setRole(user?.role)
    } catch (error: any) {
      console.log(error)
    }
  }



  if(authed === null){
    return(
      <>
        <Loader/>
      </>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      {/* <Router> */}
      <AuthContext.Provider
        value={{ authed, setAuthed, userProfile, setUserProfile }}
      >
        <Routes>
          <Route
            path="/unauthorized"
            element={
              // Route to the unauthorized page
              <UnAuth />
            }
          />

          <Route path="/login" element={<SignUpLoginLayout url="login" />} />

          <Route path="/signup" element={<SignUpLoginLayout url="signup" />} />

          <Route
            path="/forgotpassword"
            element={<SignUpLoginLayout url="forgetpassword" />}
          />

          <Route
            path="/"
            element={<LandingPageLayout landingState={"menteeLanding"} />}
          />

          <Route
            path={"/signup/emailVerification"}
            element={<StepperLayout SignupType="EmailSignup" />}
          />
          <Route path={"/role"} element={<StepperLayout SignupType="Role" />} />
          <Route
            path={"/menteestepper"}
            element={
              <MenteeStepperAuth>
                <StepperLayout SignupType="MenteeStepper" />
              </MenteeStepperAuth>
            }
          />
          <Route
            path={"/mentorstepper"}
            element={
              <MentorStepperAuth>
                <StepperLayout SignupType="MentorStepper" />
              </MentorStepperAuth>
            }
          />

          <Route
            path={"/auth/:role"}
            element={<SignUpLoginLayout url="signup" />}
          />

          <Route
            path={"/mentor/home"}
            element={
              // MentorAuth component is responsible for handling authentication and authorization logic for mentors.
              <MentorAuth>
                <Layout
                  Head={Header}
                  BackHeader={
                    <ToggleHeader push_front_to={`mentor/${userId}`} />
                  }
                  PageComponent={MentorDashboard}
                />
              </MentorAuth>
            }
          />

          <Route
            path={"/mentor/booking"}
            element={
              // MentorAuth component is responsible for handling authentication and authorization logic for mentors.
              <MentorAuth>
                <Layout
                  Head={Header}
                  BackHeader={
                    <BackHeader
                      push_back_to={`${
                        authed?.role === (null || "")
                          ? ""
                          : authed?.role + "/home"
                      }`}
                      curr_page="Discover Bookings"
                    />
                  }
                  PageComponent={Bookings}
                />
              </MentorAuth>
            }
          />
          <Route
            path={"/mentee/home"}
            element={
              // MenteeAuth component is responsible for handling authentication and authorization logic for mentees.
              <MenteeAuth>
                <Layout
                  Head={Header}
                  BackHeader={
                    <ToggleHeader
                      push_front_to={`${
                        authed?.role === (null || "")
                          ? ""
                          : authed?.role + "/" + userId
                      }`}
                    />
                  }
                  PageComponent={MenteeDashboard}
                />
              </MenteeAuth>
            }
          />

          <Route
            path={"/mentee/explore"}
            element={
              // MenteeAuth component is responsible for handling authentication and authorization logic for mentees.
              <MenteeAuth>
                <Layout
                  Head={Header}
                  BackHeader={
                    <BackHeader
                      push_back_to={`${
                        authed?.role === (null || "")
                          ? ""
                          : authed?.role + "/home"
                      }`}
                      curr_page="Mentor"
                    />
                  }
                  PageComponent={BrowseMentors}
                />
              </MenteeAuth>
            }
          />
          <Route
            path={"/mentee/booking"}
            element={
              //  MenteeAuth component is responsible for handling authentication and authorization logic for mentees.
              <MenteeAuth>
                <Layout
                  Head={Header}
                  BackHeader={
                    <BackHeader
                      push_back_to={`${
                        authed?.role === (null || "")
                          ? ""
                          : authed?.role + "/home"
                      }`}
                      curr_page="Discover Bookings"
                    />
                  }
                  PageComponent={MenteeBooking}
                />
              </MenteeAuth>
            }
          />
          <Route
            path={"/mentee/:userId"} // no authentication for mentee profile
            element={
              // MenteeAuth component is responsible for handling authentication and authorization logic for mentees.

              <Layout
                Head={Header}
                BackHeader={
                  <BackHeader
                    push_back_to={`${
                      authed?.role === (null || "")
                        ? ""
                        : authed?.role + "/home"
                    }`}
                    curr_page="Profile"
                  />
                }
                showMentorProfile={false}
                PageComponent={MenteeProfile}
              />
            }
          />
          <Route
            path={"/contactus"}
            element={
              // RequireAuth component is responsible for handling authentication and authorization logic for all users.
              // <RequireAuth>
              <LandingPageLayout landingState={"contactus"} />
              // </RequireAuth>
            }
          />

          <Route
            path={"/mentor/:userId"} // no authentication for mentor profile also
            element={
              // MentorAuth component is responsible for handling authentication and authorization logic for mentors
              <Layout
                Head={Header}
                showMentorProfile={true}
                PageComponent={MentorProfile}
                BackHeader={
                  <BackHeader
                    push_back_to={`${
                      authed?.role === (null || "")
                        ? ""
                        : authed?.role + "/home"
                    }`}
                    curr_page="Profile"
                  />
                }
              />
            }
          />

          <Route path="/change_password/:resetID" element={<ResetPassword />} />

          <Route path="*" element={<Navigate to={"/"} replace />}></Route>
        </Routes>
      </AuthContext.Provider>
      {/* </Router> */}
    </QueryClientProvider>
  );
}

export default App;
