import React, {  useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { IoAddCircleOutline } from "react-icons/io5";
import  { Dayjs } from 'dayjs';
import { SlOptionsVertical } from "react-icons/sl";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { getCookie } from '../../utils/cookies/cookieCurd';

interface Session {   // Define the types of the session
    date: Dayjs | null;
    from: Dayjs | null;
    to: Dayjs | null;
}

interface slots {    // Define the types of the slots
    date: string;
    from: string;
    to: string;
}

function extractDateTime(sessions : any) {    // Function to extract the date and time
    // Extract the date
    const formattedSessions = sessions && sessions.map((session: any) => {
        return {
            date: `${session?.date?.$y}-${(session?.date?.$M + 1).toString().padStart(2, '0')}-${session?.date?.$D.toString().padStart(2, '0')}`,
            from: new Date(session?.from?.$d).toTimeString().split(' ')[0],
            to: new Date(session?.to?.$d).toTimeString().split(' ')[0]
        }
    })
    
    return formattedSessions;
}



const DateSessionPicker: React.FC = () => {   // Define the component as a function component for date session picker
    const [sessions, setSessions] = useState<Session[]>([{ date: null, from: null, to: null }]);
    const [showOptions, setShowOptions] = useState(-1);
    const token = getCookie('GB_ACCESS_TOKEN');
    const [loading,setLoading] = useState(false);
    const [showAddSessionPopup, setShowAddSessionPopup] = useState(false);

    const handleShowOption = (index: number) => {   // Function to handle the show option
        if (index === showOptions) {
            setShowOptions(-1)
        } else {
            setShowOptions(index)
        }
    }

    const addSession = () => {  // Function to add the session
        setSessions([...sessions, { date: null, from: null, to: null }]);
    };

    const addSubSession = (index: number) => {      // Function to add the sub session

        const newSessions = [...sessions];
        const subTime = { date: newSessions[index]['date'], from: null, to: null };
        newSessions?.splice(index + 1, 0, subTime);
        setSessions(newSessions)
        setShowOptions(-1)
    }

    const removeSession = (index: number) => {          // Function to remove the session
        const newSessions = [...sessions];
        newSessions?.splice(index, 1);
        setSessions(newSessions);
        setShowOptions(-1)
    };

    const updateSession = (index: number, field: keyof Session, value: Dayjs | null) => {       
        setSessions(prevSessions => {
            const newSessions = [...prevSessions];
            newSessions[index][field] = value;
            return newSessions;
        });
    };

    function isDateInThePast(dateString:string) :boolean {      // Function to check if the date is in the past
        // Convert the date string to a Date object
        const inputDate = new Date(dateString);
    
        // Get the current date
        const currentDate = new Date();
    
        // Set the time part to 00:00:00 for both dates to ignore the time component
        inputDate?.setHours(0, 0, 0, 0);
        currentDate?.setHours(0, 0, 0, 0);
    
        // Compare the input date with the current date
        return inputDate < currentDate;
    }

    const splitTiming = (slots: slots[]) =>{        // Function to split the timing
        const timing = [];
        if (slots?.length === 0) {
            toast.error("Please add a session")
            return;
        }
        
        if (isDateInThePast(slots[0].date)) {
            toast.error("Invalid Date")
            return [];
        }

        for (let slot of slots) {
            const from = slot?.from;
            const to = slot?.to;
            const [from_hours, from_minutes, from_seconds] = from?.split(':')?.map(Number);
            const from_time_format = new Date();
            from_time_format?.setHours(from_hours, from_minutes, from_seconds, 0);
            const [to_hours, to_minutes, to_seconds] = to?.split(':').map(Number);
            const to_time_format = new Date();
            to_time_format?.setHours(to_hours, to_minutes, to_seconds, 0);
            
            if (from_time_format >= to_time_format) {
                toast.error("Invalid Time");
                return [];
            }
        
            // Split the timing into 30 minutes interval with 10 minutes break
            const fromTime = from?.split(':');
            const toTime = to?.split(':');
            const fromHour = parseInt(fromTime[0]);
            const fromMinute = parseInt(fromTime[1]);
            let toHour = parseInt(toTime[0]);
            let toMinute = parseInt(toTime[1]);
            let currentHour = fromHour;
            let currentMinute = fromMinute;
            
            while (currentHour < toHour || (currentHour === toHour && currentMinute < toMinute)) {
                let tohour = currentHour;
                let toMin = currentMinute + 30;
        
                if (toMin >= 60) {
                    tohour = currentHour + 1;
                    toMin = toMin % 60;
                }
        
                let nextSlotEndHour = tohour;
                let nextSlotEndMinute = toMin;
        
                // Ensure the last slot ends at the exact end time
                if (nextSlotEndHour > toHour || (nextSlotEndHour === toHour && nextSlotEndMinute > toMinute)) {
                    nextSlotEndHour = toHour;
                    nextSlotEndMinute = toMinute;
                }
        
                timing.push({
                    date: slot.date,
                    from: `${currentHour?.toString()?.padStart(2, '0')}:${currentMinute?.toString()?.padStart(2, '0')}:00`,
                    to: `${nextSlotEndHour?.toString()?.padStart(2, '0')}:${nextSlotEndMinute?.toString()?.padStart(2, '0')}:00`
                });
        
                currentHour = nextSlotEndHour;
                currentMinute = nextSlotEndMinute;
        
                // Add 10 minutes break
                currentMinute += 10;
                if (currentMinute >= 60) {
                    currentHour += 1;
                    currentMinute = currentMinute % 60;
                }
            }
        }
        
        return timing;
        
    }

    // Add the available sessions

    const handleAddSessions = async () => {
        const rawDates = extractDateTime(sessions);
       
        const dates = splitTiming(rawDates);
        if(dates && dates?.length === 0){
            setShowAddSessionPopup(false)
            return;
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/available_sessions/`,
                {
                    "availableSlots": dates,
                }
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            toast.success(response?.data?.message)
            setShowAddSessionPopup(false)
            setLoading(false)
            setSessions([{ date: null, from: null, to: null }]);
        } catch (error: any) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <>
            <div>
                {/* Confirm your available sessions */}
                {showAddSessionPopup && (
                <div className='fixed z-10 !inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center' onClick={()=>setShowAddSessionPopup(false)}>
                    <div className='bg-white p-5 rounded-md sm:w-[400px] w-[300px] flex flex-col gap-[16px]' onClick={(e)=> e?.stopPropagation()}>
                        <h2 className='sm:text-2xl text-xl font-semibold'>Confirm Your New Sessions</h2>
                        <div className='flex flex-row-reverse gap-[16px] '>
                                {
                                    loading === false ? <div>
                                        <button className="bg-gbpurple hover:opacity-85 ease-in duration-100 text-white px-[16px] py-[8px] rounded-lg" onClick={handleAddSessions}>Confirm</button>
                                    </div>
                                        :
                                        <button className="text-white bg-gbpurple hover:opacity-85 ease-in duration-100 rounded-lg  px-[16px] py-[8px] gap-4 flex items-center justify-center">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                                                    <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                                                </path>
                                            </svg>
                                        </button>
                                }
                                <button onClick={() => setShowAddSessionPopup(false)} className='bg-gray-400 hover:bg-gray-500  ease-in duration-100 text-white px-[16px] py-[8px] rounded-lg'>Close</button>
                        </div>   
                    </div>
                </div>
                )}
                <Toaster/>
                {/* component for date picker */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='flex flex-col gap-y-[32px] duration-200 ease-in items-center'>
                        {sessions.map((session, index) => (
                            <div key={index} className='flex flex-row sm:gap-[16px] gap-[12px] duration-200 ease-in relative '>
                                {/* date picker */}
                                <DatePicker  
                                    label="Pick Date"
                                    value={session?.date}
                                    onChange={(newValue: any) => updateSession(index, 'date', newValue)}
                                    className='md:w-[150px] sm:w-[130px] w-[100px] h-[40px]'
                                />
                                {/*start  time picker */}
                                <TimePicker
                                    label="From"
                                    value={session?.from}
                                    onChange={(newValue: any) => updateSession(index, 'from', newValue)}
                                    className='md:w-[150px] sm:w-[130px] w-[100px] h-[40px]'
                                />
                                {/* end  time picker */}
                                <TimePicker
                                    label="Till"
                                    value={session?.to}
                                    onChange={(newValue: any) => updateSession(index, 'to', newValue)}
                                    className='md:w-[150px] sm:w-[130px] w-[100px] h-[40px]'
                                />
                                <div className='flex items-center translate-y-2' title='options'>
                                    <button className=' rounded-full w-full h-full text-black text-[16px] hover:bg-gray-100 duration-100 ease-in p-[8px]' onClick={() => handleShowOption(index)}>
                                        <SlOptionsVertical />
                                    </button>
                                </div>
                                <div className={`${index === showOptions ? 'flex absolute sm:-right-[80px] right-[35px] sm:top-0 top-3' : 'hidden'} p-[4px]  flex-col gap-[8px] border-2 border-gray-100 bg-white z-10  shadow-sm duration-200 ease-in rounded-[8px]`}>
                                    <div className=''>
                                        <button className='min-w-[62.74px] rounded-[8px] h-full px-[8px] py-[4px] hover:bg-gray-100' onClick={() => addSubSession(index)}>
                                            Add
                                        </button>
                                    </div>
                                    {/* removing the available session list and minimum one session needs to be displayed */}
                                    {
                                        sessions.length > 1 && <div>
                                            <button className='rounded-[8px] w-full h-full px-[8px] py-[4px] hover:bg-gray-100' onClick={() => removeSession(index)}>
                                                Cancel
                                            </button>
                                        </div>
                                    }
                                   
                                </div>

                            </div>

                        ))}
                    </div>
                    <div onClick={addSession} color="primary" aria-label="add session" className='mt-[1rem] p-[16px] flex items-center justify-center cursor-pointer'>
                        <IoAddCircleOutline className='text-[24px] text-blue-500' title='Add session' />
                    </div>
                </LocalizationProvider>
                {/* Add more available session slot */}
                <button className="bg-gbpurple hover:opacity-85 ease-in duration-100 text-white p-[16px] rounded-lg" onClick={()=>setShowAddSessionPopup(true)}>Add Session</button>
            </div>
        </>
    );
}

export default DateSessionPicker;