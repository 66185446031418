import { MentorImages } from "../MentorUtils";
import { validateFirstName, ValidatePhonenumber, 
        validateDOBFormat, validateCountryState,
        validateAdditionDatasArray, validateAdditionalDatas,
        validateProfilePicture } from "../stepperValidation/menteeValidation";

//day form date ex: 01/01/2024
function getDayFromDate(date: string) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date(date);
    return days[d.getDay()];
}

// 01/01/2024 to jan 1
function getMonthDateFromDate(date: string) {
    if (!date) return null;
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "short" });
    return `${month} ${d.getDate()}`;
}

function convertToAMPM(time: string) {
    // Split the time into hours and minutes
    if (!time) return null;
    const [hours, minutes] = time.split(':');

    // Create a new Date object and set the hours and minutes
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    // Format the time in 12-hour format
    const ampmTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return ampmTime;
}

 const handleError = ( profile_data : any, form_error : any, handleFormError : any  ) => {
    const usernameValidation = validateFirstName(profile_data.first_name);
    const phoneValidation = ValidatePhonenumber(profile_data.phone_number);
    const dobValidation = validateDOBFormat(profile_data.date_of_birth);
    const countryValidation = validateCountryState(
        profile_data.country,
        "country"
    );
    const cityValidation = validateCountryState(profile_data.city, "city");
    const profilePicValidation = validateProfilePicture(profile_data.profile_picture_url, MentorImages[0].url)
    const roleValidation = validateAdditionalDatas(profile_data.role, "role");
    const organizationValidation = validateAdditionalDatas(
        profile_data.organization,
        "organization"
    );
    const descriptionValidation = validateAdditionalDatas(
        profile_data.description,
        "description"
    );
    const languagesValidation = validateAdditionDatasArray(
        profile_data.languages,
        "languages"
    );
    const areas_of_expertiseValidation = validateAdditionDatasArray(
        profile_data.areas_of_expertise,
        "areas_of_expertise"
    );

    const allValidation = [
        usernameValidation,
        phoneValidation,
        countryValidation,
        cityValidation,
        dobValidation,
        profilePicValidation,
        roleValidation,
        organizationValidation,
        descriptionValidation,
        languagesValidation,
        areas_of_expertiseValidation,
    ];

    let isFormValid = true;

    allValidation.forEach((validation) => {
        if ((validation as { error: boolean }).error) {
            isFormValid = false;
            handleFormError((prev: typeof form_error)=>{
                return {
                    ...prev,
                    [validation.key ]: validation.bucket,
                }
            });
        } else {
            handleFormError((prev: typeof form_error)=>{
                return {
                    ...prev,
                    [validation.key ]: [],
                }
            })
        }
    });

    return isFormValid;
};

export {getDayFromDate , getMonthDateFromDate, convertToAMPM, handleError}