import React, { useState } from 'react'
import hostedImageUrls from '../../utils/hosted_image'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

interface ForgotPasswordInterface {
    setLogin: Function
}

//  Components handle the forget password functionality

const ForgotPassword = (props: ForgotPasswordInterface) => {
    const {setLogin} = props;
    const navigate = useNavigate()
    const [role, setRole] = useState('role');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = React.useState(1)
    const [isEmailSent, setIsEmailSent] = React.useState(false)

    // email regex

    const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    // function to handle the forget password functionality

    const handleForgotPassword = async() =>{
    
      setLoading(0)
      
      if(!emailRegex.test(email)){
        toast.error('Please enter a valid email')
        setLoading(1)
        return
      }
      if (role === 'role') {
        toast.error('Please select a role')
        setLoading(1)
        return
      }

      try{

        // api call to send the reset password link to the email

        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/forgot_password/`, {
          email_id:email, user_type:role
        })

        // if response is 200 then show the success message

        if(response?.status === 200){
          setIsEmailSent(true)
          toast.success('Password reset link sent to your email')
        }
        setLoading(1)
      }
      catch(error :any){

        // if response is not 200 then show the error message

        toast.error(error.response?.data?.message)
        console.log(error)
        setLoading(1)
      }
    }

    return (
      <div>
        <Toaster/>
        <div className="flex flex-row gap-2 items-center justify-end px-4 py-2">
          <p className="text-base -tracking-[0.064px] font-normal font-CircularStdMedium ">
            back to login
          </p>

          {/* Navigation to the login */}

          <button
            className="text-base rounded border-[2px] border-black font-normal font-CircularStdMedium p-1"
            onClick={() => {
              setLogin("login");
              navigate('/login')
            }}
          >
            Login
          </button>
        </div>
        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-50px)]">
          <div>
            <img
              src={hostedImageUrls.growbinar_logo_short}
              alt="growbinar_logo"
              className="w-[150px] h-[150px] flex-shrink-0"
            />
          </div>
          <div className="">
            <p className="text-center sm:text-[36px] text-[20px] font-light font-CircularStdBlack text-[#9F9F9F] whitespace-nowrap">
                Enter your email to reset your password
            </p>
          </div>

          {/* Email verification for password reset */}

          {isEmailSent && (<div className='text-2xl'>Password reset link sent to your email</div>)}

    

          { !isEmailSent &&(<div className="flex flex-col gap-4 p-8 w-full items-center justify-center">

            {/* Enter the mail */}
            <input
              type="text"
              value={email}
              placeholder="Enter your email to reset your password"
              onChange={(e) =>setEmail(e.target.value)}
              className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2"
            />
            {/* Enter the password */}
            <select className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2" onChange={(e : any)=>setRole(e.target.value)} disabled = {loading===0?true:false}>
              <option value={'role'} disabled selected hidden>Role</option>
              <option className="" value={'Mentee'}>Mentee</option>
              <option value={'Mentor'}>Mentor</option>
            </select>
            {/* handle submission */}
            {
                loading === 1 ? <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center">
                  <p className="text-base font-CircularStdMedium font-normal" onClick={()=>{handleForgotPassword()}}>
                    Submit
                  </p>
                </button>
                :
                  <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 flex items-center justify-center">
  
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                      </path>
                    </svg>
  
                  </button>
              }
          </div>)}
        </div>
      </div>
    )
}

export default ForgotPassword