import { useState } from "react";
import toast from "react-hot-toast";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MentorExpericence } from "../../containers/MentorProfile/PrivateMentor";
// interface MentorExpericence {
//   // Define the types of props that the mentor experience component will receive
//   designation: string;
//   organization: string;
//   description: string;
//   startDate: string;
//   endDate: string;
//   is_education?:boolean;
// }

interface popupProps {
  // Define the types of props that the component will received by the pop up component
  setPopup: Function;
  setExperience: Function;
  experience: MentorExpericence | undefined;
  handleAddExperience: Function;
  loader: boolean;
}

// Add the mentee experience to the profile

const AddExperiencePopUp = ({
  setPopup,
  setExperience,
  experience,
  handleAddExperience,
  loader,
}: popupProps) => {
  const [currentWorking, setCurrentWorking] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const experienceContent: {
    [key: string]: {
      title: string;
      organization: string;
      designation: string;
      stillDoing: string;
      buttonText: string;
    };
  } = {
    education: {
      title: "Add your education",
      organization: "Institution",
      designation: "Branch of Study",
      stillDoing: "studying here",
      buttonText: "Add Education",
    },
    experience: {
      title: "Add your experience",
      organization: "Organization",
      designation: "Designation",
      stillDoing: "working on this role",
      buttonText: "Add Experience",
    },
  };


  const handleSubmit = () => {
    // function to handle the addition of new experience
    if (
      experience?.organization.trim() &&
      experience?.designation.trim() &&
      experience?.startDate.trim() &&
      confirm
    ) {
      if (experience?.endDate.trim() || currentWorking) {
        handleAddExperience();
        setConfirm(false);
        setCurrentWorking(false);
      } else {
        toast.error("Please fill the end date");
      }
    } else {
      toast.error("Please fill all the details");
    }
  };

  const handleValueChange = (key: string, value: string) => {
    // function to handle the change in the value of the input fields
    setExperience((prev: MentorExpericence) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <div
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg flex flex-col gap-4 min-w-[400px]"
      onClick={(e) => e.stopPropagation()}
    >
      {/* Add new experience */}
      <div className="w-full flex justify-between">
        <div className="text-2xl font-CircularStdBold">
          {
            experienceContent[
              experience?.is_education === true ? "education" : "experience"
            ]?.title
          }
        </div>
        <button
          onClick={() => {
            setExperience({
              designation: "",
              organization: "",
              description: "",
              startDate: "",
              endDate: "",
            });
            setConfirm(false);
            setCurrentWorking(false);
            setPopup(false);
          }}
        >
          <IoCloseCircleOutline className="text-3xl" />
        </button>
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center flex-col sm:flex-row">
        <div className="text-xl min-w-[150px] font-CircularStdMedium">
          {
            experienceContent[
              experience?.is_education === true ? "education" : "experience"
            ]?.organization
          }
        </div>
        <input
          type="text"
          className="border border-[#c0c0c0] sm:w-[250px] px-2 rounded-md w-full min-h-[40px]"
          value={experience?.organization}
          onChange={(e) => {
            handleValueChange("organization", e?.target?.value);
          }}
        />
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center flex-col sm:flex-row">
        <div className="text-xl min-w-[150px] font-CircularStdMedium">
          {
            experienceContent[
              experience?.is_education === true ? "education" : "experience"
            ]?.designation
          }
        </div>
        <input
          type="text"
          className="border border-[#c0c0c0] sm:w-[250px] px-2 rounded-md w-full min-h-[40px]"
          value={experience?.designation}
          onChange={(e) => {
            handleValueChange("designation", e?.target?.value);
          }}
        />
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center">
        <input
          type="checkbox"
          onChange={() => {
            setCurrentWorking(!currentWorking);
          }}
          checked={currentWorking}
        />
        <div className="font-CircularStdMedium">
          I'm currently{" "}
          {
            experienceContent[
              experience?.is_education === true ? "education" : "experience"
            ]?.stillDoing
          }
        </div>
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center flex-col sm:flex-row">
        <div className="text-xl min-w-[150px] font-CircularStdMedium">
          Start date
        </div>
        <input
          type="date"
          className="border border-[#c0c0c0] sm:w-[250px] px-2 rounded-md w-full min-h-[40px]"
          value={experience?.startDate}
          onChange={(e) => {
            handleValueChange("startDate", e?.target?.value);
          }}
        />
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center flex-col sm:flex-row">
        <div className="text-xl min-w-[150px] font-CircularStdMedium">
          End date
        </div>
        {currentWorking ? (
          <input
            type="date"
            readOnly
            className="border opacity-50 cursor-not-allowed border-[#c0c0c0] sm:w-[250px] px-2 rounded-md w-full min-h-[40px]"
            value={""}
          />
        ) : (
          <input
            type="date"
            className="border border-[#c0c0c0] sm:w-[250px] px-2 rounded-md w-full min-h-[40px]"
            value={experience?.endDate}
            onChange={(e) => {
              handleValueChange("endDate", e?.target?.value);
            }}
          />
        )}
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-start flex-col sm:flex-row">
        <div className="text-xl min-w-[150px] font-CircularStdMedium">
          Description
        </div>
        <textarea
          className="border border-[#c0c0c0] sm:w-[250px] px-2 py-2 sm:py-0 rounded-md w-full min-h-[40px]"
          value={experience?.description}
          onChange={(e) => {
            handleValueChange("description", e?.target?.value);
          }}
        />
      </div>
      <div className="flex gap-2 sm:gap-4 sm:items-center">
        <input
          type="checkbox"
          onChange={() => {
            setConfirm(!confirm);
          }}
          checked={confirm}
        />
        <div className="font-CircularStdMedium">
          The information provided above is correct
        </div>
      </div>
      {loader ? (
        <div>
          <button className="w-full flex justify-center items-center add-exp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              >
                <animateTransform
                  attributeName="transform"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </button>
        </div>
      ) : (
        <button
          className="add-exp"
          onClick={() => {
            handleSubmit();
          }}
        >
          {
            experienceContent[
              experience?.is_education === true ? "education" : "experience"
            ]?.buttonText
          }
        </button>
      )}
    </div>
  );
};

export default AddExperiencePopUp;
