import React, {useState} from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { FaRegClock } from "react-icons/fa6";
import Cookies from "js-cookie";
import toast,{Toaster} from "react-hot-toast";
import axios from "axios";
import { getDayFromDate, getMonthDateFromDate, convertToAMPM } from "../../utils/HelperFunctions";


interface ConfirmBookingProps{    // Define the types of props that the component will receive
    setPopUp : Function;
    start_date: string;
    start_time: string;
    end_time: string;
    mentor_id: string | undefined;
    userDetails?:any;
}

const token = Cookies.get('GB_ACCESS_TOKEN')   // Get the token from the cookie

const ConfirmBooking : React.FC<ConfirmBookingProps> = ({setPopUp, userDetails, start_date, start_time, end_time, mentor_id}) =>{
    // Define the component as a function component and pass the props to it

    const [sessionCreationLoader, setSessionCreationLoader] = useState<boolean>(false);
    const [sesssionBookingReason, setSessionBookingReason] = useState<string>("");
    const [otherReason, setOtherReason] = useState<string>("");
  
    const talkAbout = ["Portfolio review", "Getting Placed", "Cracking interviews", "Skills"]


    const handleSessionCreation = async () => {   // Function to handle the session creation
        if (!token) {    // if token not found the user can't perform any operation only view
            toast.error("Please login to book a session")
            return;
        }
        const reason = sesssionBookingReason === "Others" ? otherReason : sesssionBookingReason;

        if (reason === "") {
            toast.error("Please select a reason to book a session")
            return;
        }

        try {
            setSessionCreationLoader(true);
            const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/new_sessions/`, {   // request to create a new session
                start_date: start_date, // start date of the session
                start_time: start_time, // start time of the session
                end_time: end_time, // end time of the session           
                reason:reason, // reason for the session
                mentor_id: mentor_id, // mentor id
     
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Fixed the typo in the header name
                }
            })
            setPopUp(false)
            toast.success(response?.data?.message);
           
            setSessionCreationLoader(false);
        } catch (error: any) {
            setSessionCreationLoader(false);    
            toast.error(error?.response?.data?.message)
        }
    }

    const handleSessionReason = (reason:string)=>{   // Function to handle the session reason
        if (reason === sesssionBookingReason){
            setSessionBookingReason("")
            return;
        }
        setSessionBookingReason(reason)
    }

    return(
        <>
            <Toaster/>
            <div className="fixed inset-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-20" onClick={() => setPopUp(false)}>
                <div className="!bg-white rounded-xl p-[24px] text-black relative flex flex-col gap-[16px] max-w-[400px] !leading-none" onClick={(e)=> e?.stopPropagation()}>
                    <button className="absolute top-[16px] right-[16px]" onClick={() => setPopUp(false)}>
                        <IoCloseCircleOutline className="text-[24px] hover:text-red-600" />
                    </button>
                    <div className="flex flex-col gap-[8px] mt-[16px] items-center">
                        <h1 className="text-xl font-medium">Confirm the Booking</h1>
                        <div className="flex flex-row gap-[8px] items-center">
                            <p className="text-gray-600 text-lg font-medium capitalize">Confirm your session with {userDetails?.name} </p>
                            <img className="w-[30px] h-[30px] rounded-full" src={userDetails?.profile_image_url} alt="profile"></img>
                        </div>

                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-[#3F3D4D] font-medium">Selected time slot</h4>
                        <div className="flex flex-row gap-[16px]">
                            <div className="p-[12px] rounded-[8px] bg-[#F5F5F5] flex flex-row gap-[8px] items-center">
                                <SlCalender className="text-[16px] font-medium" />
                                <p className="font-medium">{getMonthDateFromDate(start_date)}, {getDayFromDate(start_date)}</p>
                            </div>
                            <div className="p-[16px] rounded-[8px] bg-[#F5F5F5] flex flex-row gap-[8px] items-center">
                                <FaRegClock className="text-[16px] font-medium" />
                                <p className="font-medium">{convertToAMPM(start_time)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-[#3F3D4D] font-medium">Tell mentor what do you want to talk about</h4>
                        <div className="flex flex-row flex-wrap gap-[16px]">
                            {
                                talkAbout.map((talk,index)=>{
                                    return(
                                        <button key={index} className={`${talk === sesssionBookingReason ? "border-2 !border-txtPurple" : "border-txtGrey"} p-[12px] whitespace-nowrap border bg-[#FBFBFE]  rounded-[8px] `} onClick={() => handleSessionReason(talk)}>
                                            {talk}
                                        </button>
                                    )
                                })
                            }
                            <button className={`${ "Others" === sesssionBookingReason ? "border-2 !border-txtPurple" : "border-txtGrey"} p-[12px] whitespace-nowrap border bg-[#FBFBFE] border-txtGrey rounded-[8px] `} onClick={() => handleSessionReason("Others")}>
                                Others
                            </button>

                           <div>
                                 {
                                      sesssionBookingReason === "Others" ? (
                                        <input type="text" placeholder="Enter your reason" className="border-2 border-txtGrey p-[12px] rounded-[8px] w-full" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setOtherReason(e?.target?.value)} />
                                      ) : null
                                 }
                           </div>
                    
                        </div>
                    </div>
                    {
                        sessionCreationLoader === true ? (
                            <div>
                                <button className="w-full p-[16px] bg-txtPurple text-white text-2xl font-semibold rounded-x flex items-center justify-center rounded-xl"> <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                                        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                                    </path>
                                </svg></button>
                            </div>
                        ) : (
                            <div>
                                <button className="w-full p-[16px] bg-txtPurple text-white  font-medium rounded-xl mt-[12px]" onClick={handleSessionCreation}>Book session for 06 Jan 2024</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default ConfirmBooking;